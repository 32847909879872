<template>
  <div>
    <vs-popup class="sm:popup-w-60" title="Tambah Refund" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Currency *</label>
            <vs-select class="w-full" v-model="data.id_currency" disabled>
              <vs-select-item v-for="(it, index) in currencies" :key="index" :value="it.id" :text="it.nama"/>
            </vs-select>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Cara Bayar *</label>
            <vs-select class="w-full" v-model="data.id_cara_bayar">
              <vs-select-item v-for="(it, index) in caraBayars" :key="index" :value="it.id" :text="it.nama"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Tgl Refund *</label>
            <flat-pickr class="w-full" v-model="data.tgl_refund"></flat-pickr>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Jml Refund *</label>
            <v-money class="w-full" type="number" v-model="data.jml_refund"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan" />
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import KontrakRefundRepository from '@/repositories/marketing/kontrak/kontrak-refund-repository'
import CurrencyRepository from '@/repositories/master/currency-repository'
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'

export default {
  name: 'RefundAdd',
  props: ['isActive'],
  components: {
    VMoney,
    ValidationErrors,
    flatPickr
  },
  watch: {
    isActive (value) {
      if (value && !this.isDataInited) {
        this.initData()
      }
    }
  },
  data () {
    return {
      isDataInited: false,
      isLoading: false,
      errors: null,
      data: {
        id_currency: 1 // IDR
      },
      currencies: [],
      caraBayars: []
    }
  },
  methods: {
    initData () {
      this.getAllCurrency()
      this.getAllCaraBayar()
      this.isDataInited = true
    },

    getAllCurrency () {
      CurrencyRepository.getAll()
        .then(response => {
          this.currencies = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllCaraBayar () {
      CaraBayarRepository.getAll()
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true
      const params = { ...this.data, id_kontrak: this.$route.params.idKontrak }
      const idKontrak = this.$route.params.idKontrak

      KontrakRefundRepository.create(idKontrak, params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['currencies', 'caraBayars'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
